.ant_modal_content {
    padding: 0;
    max-height: 80vh;
    background: transparent !important;
    text-align: center;
    box-shadow: none !important;
    :global(.ant-modal-close) {
        top: 72px;
        right: -13px;
        opacity: 0;
    }
}

.search_logo {
    width: 1200px;
    border-radius: 20px;
    vertical-align: bottom;
}

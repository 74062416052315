.m-tool-bar {
    pointer-events: none;
    position: fixed;
    right: 20px;
    bottom: 100px;
    width: 50px;
    z-index: 100;
    text-align: center;
    border-radius: 8px;
    ul {
        padding-top: 12px;
        li a,
        .tool-bar-menu {
            cursor: pointer !important;
            display: block;
            float: right;
            position: relative;
            right: 0;
            width: 50px;
            margin-bottom: 12px;
            span {
                display: block;
                margin-top: 4px;
                color: #333333;
                font-size: 11px;
                transform: scale(0.9);
            }
        }

        .tool-bar-Statute {
            margin-bottom: 5px;
            .bg-white {
                margin-bottom: 0;
            }
            img {
                width: 28px;
                height: 27px;
            }
            .tool-bar-Divider {
                width: 35px;
                height: 1px;
                background: #F4F4F4;
            }
        }

        .tool-bar-service {
            img {
                width: 28px;
                height: 27px;
            }
        }
        .tool-bar-line {
            a {
                margin-bottom: 0;
            }
            img {
                width: 26px;
                height: 24px;
            }
        }
        .tool-bar-menu {
            height: 50px;
            width: 50px;
            border-radius: 8px;
            background: #fff;
            margin-bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .tool-bar-icon-menu {
                width: 24px;
                height: 20px;
                margin-left: 4px;
                cursor: pointer;
            }
        }
    }
}
// .m-tool-bar li > div:after,
// .m-tool-bar li a:after {
//     background: #d8d8d8;
//     bottom: 0;
//     content: '';
//     height: 1px;
//     position: absolute;
//     right: 10px;
//     width: 28px;
//     margin-top: 5px;
// }

.tool-bar-show,
.tool-bar-show > ul {
    pointer-events: all;
}
.tool-bar-show li:not(.tool-bar-menu) {
    opacity: 1;
    // transform: translateX(0);
}
.m-tool-bar li:first-child {
    transition-delay: 0ms;
}

.tool-bar-helpCenter img {
    width: 28px;
    height: 28px;
}
.msg-count {
    min-width: 20px;
    background-color: #e94e58;
    // padding:4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    position: absolute;
    top: 0;
    right: 2px;
}

.tool-bar-icon-cart {
    background-position: 0 0;
}

.tool-bar-icon-pkg {
    background-position: -26px 0;
}

.tool-bar-icon-online {
    background-position: -52px 0;
}

.tool-bar-icon-advice {
    background-position: -78px 0;
}

.tool-bar-icon-top {
    background-position: -130px 0;
}

.tool-bar-icon-menu-active {
    background-position: -156px 0;
}

.tool-bar-icon-app {
    background-position: -182px 0;
}

.tool-bar-icon-tools {
    background-position: -212px 1px;
}
.tool-bar-menu .tool-bar-icon-menu {
    background-position: -237px 0;
}

.tool-bar-show .tool-bar-icon-menu {
    background-position: -104px 0 !important;
}

.m-tool-bar * {
    font-family: muliBold;
}

.m-tool-bar ul {
    filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.1));
    pointer-events: none;
}

.m-tool-bar ul .tool-bar-menu {
    pointer-events: all;
}

.m-tool-bar .clearfix:before {
    clear: both;
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
}

.m-tool-bar .clearfix,
.m-tool-bar ul {
    *zoom: 1;
}

.m-tool-bar ul:after {
    clear: both;
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
}

.m-tool-bar li {
    *zoom: 1;
    cursor: pointer;
    margin-left: 0;
    opacity: 0;
    transform: translateX(100%);
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.m-tool-bar li:after {
    clear: both;
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
}

.m-tool-bar li:last-child a:after {
    display: none;
}

.m-tool-bar li > div {
    display: inline-block;
}

.m-tool-bar li:first-child {
    transition-delay: 0ms;
}

.m-tool-bar li:nth-child(2) {
    transition-delay: 35ms;
}

.m-tool-bar li:nth-child(3) {
    transition-delay: 70ms;
}

.m-tool-bar li:nth-child(4) {
    transition-delay: 105ms;
}

.m-tool-bar .tool-bar-menu {
    opacity: 1;
    transform: translateX(0);
}

.m-tool-bar .tool-bar-menu a {
    background: #fff;
    border-radius: 8px;
}
.tool-bar-show,
.tool-bar-show > ul {
    pointer-events: all;
}

.tool-bar-show > ul {
    background: #fff;
    border-radius: 8px;
    filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.1));
}

.tool-bar-show li:not(.tool-bar-menu) {
    opacity: 1;
    transform: translateX(0);
}

.App{
    .menu-slider{
      &.en{
        .menu-item-warp{
          span{
            word-wrap: break-word;
            font-size:12px;
            max-width: 130px;
            white-space: normal;
            line-height: 1.2;
          }
        }
        .ant-menu-title-content{
          word-wrap: break-word;
          font-size:12px;
          white-space: normal;
          line-height: 1.2;
          .layout{
            top:0;
            left:66px;
          }
        }
      }
        .ant-menu{
            .ant-menu-item, .ant-menu-submenu{
                margin: 14px 10px !important;
                padding: 0 !important;
                width: auto;
                text-align: left;
                ul {
                    .ant-menu-item, .ant-menu-submenu{
                        margin: 6px 0px !important;
                        height: 34px;
                    }
                }
            }
            .ant-menu-submenu-title{
                padding: 0 !important;
                width: auto;
                margin : 0;
                color: var(--color-white)
            }
            .ant-menu-title-content{
              font-weight: 600;
            }
            .ant-menu-submenu-title:hover{
                font-weight: 600;
            }
        }
    }
}
#J_D2D {
  min-width: 1200px;
  overflow: auto;
}

.ellipsis_2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 设置最大显示行数 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-all;
}
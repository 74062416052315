@keyframes fly {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(180px,0);
    }
  
  }
  .agentToDirectLoading {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 2001;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .bg{
    position: relative;
    
  }
  .airport{
    position: absolute;
    left: 150px;
    top: 95px;
    animation: fly 1.5s ease-in-out infinite;
  }
